import template from "./language-selector.html?raw";
import "./language-selector.scss";
import Util, {
  cloneRouteLocation,
} from "@mediktor-web/sdk/src/modules/core/lib/Util";
import LanguageBO from "@mediktor-web/sdk/src/modules/core/business/LanguageBO";
import {
  QBtn,
  QList,
  QItem,
  QItemLabel,
  QItemSection,
  QBtnDropdown,
} from "quasar";
import OriginalLanguageSelector from "@mediktor-web/sdk/src/app/components/layout/language-selector/LanguageSelector";

export default {
  extends: OriginalLanguageSelector,
  template,
  components: {
    QBtn,
    QList,
    QItem,
    QItemLabel,
    QItemSection,
    QBtnDropdown,
  },

  props: {
    color: { default: "grey-7" },
    border: { default: false },
  },

  data() {
    return {
      selectedLanguage: null,
    };
  },

  watch: {
    $route() {
      this.setCurrentLanguage();
    },
  },

  methods: {
    onLanguageItemTap(item) {
      this.changeLanguage(item.id);
    },
    /*
     * @deprecated
     */
    async changeLanguage(selectedLanguage) {
      /*this.$app.isChangingLanguage = true;

            const currentRoute = this.$router.currentRoute;

            document.body.style.opacity = .8;

            const newRoute = await this.$app.translateRouteIntoNewLanguage(currentRoute, selectedLanguage);

            await this.app().changeAppLanguage(Util.convertServerLanguageToIso(selectedLanguage));

            let query = currentRoute.query;
            query.langChange = 1;

            //let resolvedRoute = this.$router.resolve({name: newRoute.name, params: newRoute.params, query});

            await this.$router.push(newRoute.href);

            this.$app.isChangingLanguage = false;

            this.$app.reloadLayout();

            document.body.style.opacity = 1;*/
    },
    goToRoute(language) {
      const query = {};
      Object.assign(query, this.$route.query);
      query["lang"] = language.replace("_", "-");

      this.$router
        .push({
          path: this.$route.path,
          query,
        })
        .catch(() => {});
    },
    setCurrentLanguage() {
      this.selectedLanguage = LanguageBO.getInstance().currentLanguage();
    },
  },

  computed: {
    isFlat() {
      return [
        ...this.commonButtonAttributes,
        {
          flat: this.border,
        },
      ];
    },
    selectedLanguageName() {
      return LanguageBO.getInstance().currentLanguage().originName;
    },
    selectedLanguageImage() {
      if (this.selectedLanguage != null) {
        if (this.selectedLanguage.mediaFileList !== undefined) {
          return this.selectedLanguage.mediaFileList[0].mediaURL;
        }
      }

      return null;
    },
    languageList() {
      let languageOrderedListByName = [];

      LanguageBO.getInstance()
        .getLanguageList(false)
        .forEach((language) => {
          let image = null;

          if (language.mediaFileList !== undefined) {
            image = language.mediaFileList[0].mediaURL;
          }

          let route = cloneRouteLocation(this.$route);
          route.query["lang"] = language.languageCode.replace("_", "-");

          languageOrderedListByName.push({
            title: language.originName,
            id: language.languageCode,
            leading: `${image}&s=10`,
            route,
          });
        });

      languageOrderedListByName = languageOrderedListByName.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      return languageOrderedListByName;
    },
    hasLanguageList() {
      return this.languageList.length > 0;
    },
  },

  mounted() {
    this.setCurrentLanguage();
  },
};

import template from "./drawer.html?raw";
import OriginalDrawer from "@mediktor-web/sdk/src/modules/core/components/interface/drawer/Drawer.js";
import mmdkIcon from "../../resources/img/mmdkIcon.png";
import mixins from "vue-typed-mixins";

import "./drawer.scss";

export default mixins(OriginalDrawer).extend({
  template,
  computed: {
    drawerIcon(): string {
      return mmdkIcon;
    },
  },
});

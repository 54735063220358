import OriginalRouter from "@mediktor-web/sdk/dist/src/modules/core/lib/Router";
import LanguageBO from "@mediktor-web/sdk/src/modules/core/business/LanguageBO";
import RouteGenerator from "@mediktor-web/sdk/src/modules/core/lib/RouteGenerator";

export default class Router extends OriginalRouter {
  generateRouteList(lang, includeNoLangRoutes = true) {
    let allRoutes = super.generateRouteList(lang, includeNoLangRoutes);
    let routes = [];

    if (includeNoLangRoutes) {
      routes.push({
        name: "sitemapGenerator",
        path: "/sitemap-generator",
        component: () => import("../components/SitemapGenerator"),
      });
    }

    routes = routes.concat(allRoutes);

    return routes;
  }

  layoutRoutes(routes, appLanguage) {
    let filter = routes.filter(
      (route) =>
        route.name !== "home" &&
        route.name !== "checker" &&
        route.name !== "certificate",
    );
    let languageList = LanguageBO.getInstance()
      .getLanguageList()
      .filter((item) => item.isActive)
      .map((item) => item.languageCode);

    function prepareName(name, lang) {
      return name + (lang !== appLanguage ? "__" + lang : "");
    }

    for (let lang of languageList) {
      let language = lang.replace("_", "-");
      let publicLanguage = LanguageBO.convertToPublicLanguage(language);

      filter.push(
        ...[
          {
            name: prepareName("home", language),
            path: `/${publicLanguage}`,
            component: () => import("../components/home/Home"),
          },
          // {
          //     name: prepareName("checker", language),
          //     path: `/${publicLanguage}/checker`,
          //     component: () => import("../components/home/Home"),
          // },
          {
            name: prepareName("checker", language),
            path: `/${publicLanguage}/checker`,
            redirect: `/${publicLanguage}`,
            beforeRouteUpdate(to, from, next) {
              this.$router.push({ path: `/${publicLanguage}` }).catch(() => {});
            },
          },
          {
            name: prepareName("certificate", language),
            path: `/${publicLanguage}/certificate`,
            component: () => import("../components/certificate/Certificate"),
          },
        ],
      );
    }

    return filter;
  }
}

import ExternalAnalyticsManagerOriginal from "@mediktor-web/sdk/src/modules/core/lib/ExternalAnalyticsManager";
import EnvironmentConfig from "@mediktor-web/sdk/src/modules/core/lib/EnvironmentConfig";
import GoogleAnalyticsManager from "./GoogleAnalyticsManager";
import Vue from "vue";
import { Application } from "@mediktor-web/sdk/src/modules/core/lib/Holder";
import AppStorageManager from "@mediktor-web/sdk/src/modules/core/lib/AppStorageManager";

export default class ExternalAnalyticsManager extends ExternalAnalyticsManagerOriginal {
  constructor() {
    super();

    this.app = Application.getInstance();
    this.googleAnalytics = new GoogleAnalyticsManager(this.app);
  }

  async init() {
    if (EnvironmentConfig.getValue("APP_GTM_CONTAINER_ID")) {
      const VueGtm = await import("vue-gtm");

      Vue.use(VueGtm.default, {
        id: EnvironmentConfig.getValue("APP_GTM_CONTAINER_ID"),
        vueRouter: this.app.router,
      });

      console.info("Analytics: GTM enabled");
    }

    if (EnvironmentConfig.getValue("APP_ANALYTICS_ID")) {
      await this.googleAnalytics.setup(
        EnvironmentConfig.getValue("APP_ANALYTICS_ID"),
        this.app.router,
      );

      console.info("Analytics: GA enabled");
    }
  }

  async setup() {
    this.app.catchEvent("cookiesAccepted", () => {
      this.init();
    });

    if (AppStorageManager.getInstance().getCookies()) {
      await this.init();
    }
  }

  sendPageAnalytics(route, title = null) {
    this.googleAnalytics.sendPageAnalytics(route, title);
  }

  trackCustomEvents(
    nameRoute = null,
    eventAction = "",
    eventValue = "",
    featureName = null,
  ) {
    this.googleAnalytics.trackCustomEvents(
      nameRoute,
      eventAction,
      eventValue,
      featureName,
    );
  }
}

import { QBtn, QIcon } from "quasar";
// @ts-ignore
import template from "./LoginMyMe.html?raw";
import OriginalLogin from "@mediktor-web/sdk/dist/src/modules/user/components/login/Login";
import "./LoginMyMe.scss";

import mixins from "vue-typed-mixins";

export default mixins(OriginalLogin).extend({
  template,
  components: {
    QBtn,
    QIcon,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});

import CoreApp from "@mediktor-web/sdk/dist/src/modules/core/lib/App.js";
import {
  contentLocales,
  routeLocales,
} from "@mediktor-web/sdk/dist/src/locales.js";
import interfaceLocales from "../interfaceLocales";
export default class App extends CoreApp {
  appVisibleName(): string {
    return "My Mediktor";
  }
  localeList(): { contentLocales: string[]; routeLocales: string[] } {
    return {
      contentLocales: [...new Set([...contentLocales, ...interfaceLocales])],
      routeLocales,
    };
  }

  async checkServerInfo(override: boolean = false) {
    if (this.router?.currentRoute.name === "home" && !override) {
      return;
    }
    await super.checkServerInfo();
  }
}

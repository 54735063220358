import template from "./profile-info.html?raw";
import "./profile-info.scss";
import SpecialistCardOverview from "@mediktor-web/sdk/src/modules/specialists/components/specialist-card/SpecialistCardOverview";
import { QBtn, QIcon, QItem, QItemSection, QSeparator } from "quasar";
import CardHeader from "@mediktor-web/sdk/src/modules/core/components/interface/card-header/CardHeader";
import RouteGenerator from "@mediktor-web/sdk/src/modules/core/lib/RouteGenerator";
import { UIButton } from "@mediktor-web/sdk/src/modules/core/index";

export default {
  template,
  extends: SpecialistCardOverview,
  components: {
    QBtn,
    QIcon,
    QItem,
    QItemSection,
    CardHeader,
    QSeparator,
    UIButton,
  },

  computed: {
    profileInfoButtonLabel() {
      return this.localeText("tmk380");
    },
    bottomLinks() {
      return [
        {
          title: this.localeText("tmk267"),
          icon: "article",
          action: this.userActivityLink,
          class: "mdk-profile-info__bottom-links__container--activity",
        },
        {
          title: this.localeText("logout"),
          icon: "logout",
          action: this.userLogoutLink,
          class: "mdk-profile-info__bottom-links__container--log-out",
        },
      ];
    },
    userEmail() {
      return this.externUser.email;
    },
    userActivityLink() {
      return RouteGenerator.getInstance().profileActivity();
    },
    userLogoutLink() {
      return RouteGenerator.getInstance().logout();
    },
    userProfileLink() {
      return RouteGenerator.getInstance().profile();
    },
  },
  methods: {
    onImageUpdate() {},
  },
  dismiss() {
    this.$emit("dismiss");
  },
};

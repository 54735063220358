// @
import template from "./nav-bar.html?raw";
import "./nav-bar.scss";
import {
  QLayout,
  QHeader,
  QToolbar,
  QBtn,
  QSeparator,
  QList,
  QMenu,
  QItemSection,
  QItem,
  QIcon,
  QBtnDropdown,
  QItemLabel,
} from "quasar";
import RouteGenerator from "@mediktor-web/sdk/src/modules/core/lib/RouteGenerator";
import LanguageSelector from "../language-selector/LanguageSelector";
import UIDrawer from "../drawer/Drawer";
import originalMenu from "@mediktor-web/sdk/src/app/components/layout/menu/Menu";
import LocaleMixin from "@mediktor-web/sdk/src/modules/core/mixins/LocaleMixin";
import ProfileInfo from "../profile-info/ProfileInfo";
import PageComponentMixin from "@mediktor-web/sdk/src/app/lib/PageComponentMixin";
import LanguageBO from "@mediktor-web/sdk/src/modules/core/business/LanguageBO";
import EnvironmentConfig from "@mediktor-web/sdk/src/modules/core/lib/EnvironmentConfig";
import AudioController from "modules/core/lib/AudioController";
import AnalyticsManager from "@mediktor-web/sdk/src/modules/core/lib/AnalyticsManager";
import SessionBO from "@mediktor-web/sdk/src/modules/core/business/SessionBO.js";

import mmdkLogoBig from "../../resources/img/mmdkLogoBig.png";

export default {
  template,
  extends: originalMenu,
  mixins: [LocaleMixin, PageComponentMixin],
  components: {
    "mdk-ui-drawer": UIDrawer,
    ProfileInfo,
    QLayout,
    QHeader,
    QToolbar,
    QBtn,
    QSeparator,
    LanguageSelector,
    QList,
    QMenu,
    QItem,
    QItemSection,
    QIcon,
    QBtnDropdown,
    QItemLabel,
  },
  data() {
    return {
      drawerIsVisible: false,
      profileDropdownIsActive: false,
      scrollResetIsVisible: false,
      settingsButtonActive: false,
      dicctonaryIsActive: false,
      homeIsActive: false,
      isStartEvaluationLoading: false,
    };
  },
  watch: {
    $route() {
      this.settingsButtonActive =
        this.app().router.currentRoute.name ===
        RouteGenerator.getInstance().profileSettings().name;
      this.profileDropdownIsActive =
        this.app().router.currentRoute.name ===
        RouteGenerator.getInstance().login().name;
      this.dicctonaryIsActive =
        this.app().router.currentRoute.name ===
        RouteGenerator.getInstance().glossary().name;
      this.homeIsActive =
        this.app().router.currentRoute.name ===
        RouteGenerator.getInstance().home().name;
    },
  },
  computed: {
    symtomCheckerCtaClass() {
      return {
        "mmdk-nav-bar__toolbar__links-container__items--outline":
          !this.scrollResetIsVisible,

        "  mmdk-nav-bar__toolbar__links-container__items--green":
          this.scrollResetIsVisible,
      };
    },
    outline() {
      return { outline: !this.scrollResetIsVisible };
    },
    profileIcon() {
      return {
        "mmdk-nav-bar__toolbar__profile--active": this.profileDropdownIsActive,

        "mmdk-nav-bar__toolbar__profile--inactive":
          !this.profileDropdownIsActive,
        "mmdk-nav-bar__toolbar__profile--logged-in": this.userLoggedIn,
      };
    },
    settingsIcon() {
      return {
        "mmdk-nav-bar__toolbar__links-container__items__dictionary--active":
          this.settingsButtonActive,

        "mmdk-nav-bar__toolbar__links-container__items__dictionary--inactive":
          !this.settingsButtonActive,
      };
    },
    dicctionaryLink() {
      return {
        "mmdk-nav-bar__toolbar__links-container__items__dictionary--active":
          this.dicctonaryIsActive,

        "mmdk-nav-bar__toolbar__links-container__items__dictionary--inactive":
          !this.dicctonaryIsActive,
      };
    },

    imageForegroundStyle() {
      return {
        backgroundImage: `url(${this.externUser.imageFace || null})`,
      };
    },
    avatarDescription() {
      if (this.externUser.imageFace) return null;
      if (this.externUser && "fullName" in this.externUser)
        return this.externUser.fullName[0];
    },
    mmdkLogo() {
      return mmdkLogoBig;
    },
    checkYourSymptomsLabel() {
      return this.localeText("tmk2053");
    },
    topLinks() {
      return [
        {
          id: "business",
          label: this.localeText("tmk2052"),
          route: `https://${
            EnvironmentConfig.getValue("APP_ENV") === "int"
              ? "www.int."
              : EnvironmentConfig.getValue("APP_ENV") === "dev"
              ? "www.dev."
              : ""
          }mediktor.com?lang=${LanguageBO.getInstance().currentLanguageCode()}`,
          icon: "business",
          newTab: true,
        },
      ];
    },
    routeTopLinks() {
      return [
        {
          id: "dictionary",
          label: this.localeText("tmk372"),
          route: { name: this.app().routeDefinitionName("glossary") },
          icon: "menu_book",
        },
      ];
    },
    drawerLinks() {
      const list = [];
      list.push({
        id: "home",
        label: this.localeText("tmk282"),
        route: RouteGenerator.getInstance().home(),
        icon: "home",
      });

      return [...list, ...this.topLinks, ...this.routeTopLinks];
    },
    drawerLinksBottom() {
      const list = [];
      list.push({
        id: "settings",
        label: this.localeText("tmk373"),
        route: RouteGenerator.getInstance().profileSettings(),
        icon: "settings",
      });
      return list;
    },

    homeLink() {
      return RouteGenerator.getInstance().home();
    },

    // startNewSession () {
    //     AudioController.getInstance().setIsGrantedAudio(true)
    //     return RouteGenerator.getInstance().sessionStart(null);
    // },
    loginLabel() {
      return this.localeText("routes.login");
    },
    registerlabel() {
      return this.localeText("mdk_sign_up");
    },
    userLoggedIn() {
      return this.$app.isUserLoggedIn();
    },
    loginLink() {
      return RouteGenerator.getInstance().login();
    },
    registerLink() {
      return RouteGenerator.getInstance().register();
    },
    profileImage() {
      return this.externUser.imageFace;
    },
    settingsLink() {
      return RouteGenerator.getInstance().profileSettings();
    },
  },
  methods: {
    async startNewSession() {
      this.isStartEvaluationLoading = true;

      await this.$app.checkServerInfo(true);

      const areLegalTermsAccepted = !this.$app.legalTermsVisible.isVisible;
      this.isStartEvaluationLoading = false;

      if (areLegalTermsAccepted) {
        await this.initiateSession();
      } else {
        try {
          await this.handleTermsAndConditions();
          await this.initiateSession();
        } catch (error) {
          console.error("Session not started: ", error.message);
        }
      }
    },

    async initiateSession() {
      const newSession = await SessionBO.getInstance().doNewSession({});

      AudioController.getInstance().setIsGrantedAudio(true);
      AnalyticsManager.getInstance().trackCustomEvents(
        newSession.sessionId || this.$route.name,
        "avatarstatus_start",
        "true",
        true,
        JSON.stringify({ byuser: false })
      );
      this.$router
        .push(RouteGenerator.getInstance().session(newSession.sessionId, false))
        .catch(() => {});
    },

    handleTermsAndConditions() {
      return new Promise((resolve, reject) => {
        const element = document.querySelector(
          ".mdk-terms-conditions.mdk-terms-conditions--content-visible"
        );

        const closeTermsAndConditions = (accepted) => {
          this.$app.legalTermsVisible.isVisible = false;
          window.removeEventListener("keydown", keyDownHandler);
          if (accepted) {
            resolve();
          } else {
            reject(new Error("Terms not accepted"));
          }
        };

        const keyDownHandler = (event) => {
          if (event.key === "Escape") {
            closeTermsAndConditions(false);
          }
        };

        window.addEventListener("keydown", keyDownHandler);

        if (element) {
          element.addEventListener("click", (event) => {
            if (event.target === element) {
              closeTermsAndConditions(false);
            }
          });
        }

        this.catchEvent("userTermsAccepted", () => {
          closeTermsAndConditions(true);
        });
      });
    },
    drawerLinkClasses(link) {
      let resolvedRoute = this.$router.resolve(link.route).resolved;

      return {
        "mmdk-nav-bar__toolbar__drawer-container--active":
          this.app().router.currentRoute.name === resolvedRoute.name,

        "mmdk-nav-bar__toolbar__drawer-container--inactive":
          this.app().router.currentRoute.name !== resolvedRoute.name,
      };
    },
    drawerIsVisibleToggler() {
      return (this.drawerIsVisible = !this.drawerIsVisible);
    },
    onDrawerDismiss() {
      this.drawerIsVisible = false;
    },
    checkScrollReset() {
      if (window.scrollY > 200) {
        this.scrollResetIsVisible = true;
      } else this.scrollResetIsVisible = false;
    },
    linkRoutes(link) {
      if (link.newTab === true) {
        window.open(link.route, "_blank");
      } else {
        this.$router.replace(link.route).catch(() => {});
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.checkScrollReset);
  },
  destroyed() {
    window.removeEventListener("scroll", this.checkScrollReset);
  },
};

import EnvironmentConfig from "@mediktor-web/sdk/src/modules/core/lib//EnvironmentConfig";
import LanguageBO from "@mediktor-web/sdk/src/modules/core/business/LanguageBO";
import { AppQueryContentManager } from "@mediktor-web/sdk/src/modules/core/lib/AppQueryContentManager";
import Vue from "vue";

export default class GoogleAnalyticsManager {
  constructor(app) {
    this.app = app;
    this.analyticsInstance = null;
  }

  async setup(analyticsId, router, anonymizeIp = true, isEnabled = true) {
    const VueGtag = await import("vue-gtag");

    Vue.use(
      VueGtag.default,
      {
        config: {
          id: analyticsId,
        },
        enabled: isEnabled,
        pageTrackerTemplate(to) {
          return {
            page_path: to.path,
            page_location: window.location.href,
            page_title: to.name,
          };
        },
        onReady() {
          console.info("GA Analytics ready.");
        },
        onBeforeTrack() {
          // before!
        },
        onAfterTrack() {
          // after!
        },
      },
      router,
    );

    this.analyticsInstance = Vue.$gtag;
    this.setAnonymization(anonymizeIp);
  }

  setAnonymization(value) {
    if (this.analyticsInstance) {
      this.analyticsInstance.set("anonymize_ip", value);
    }
  }

  sendPageAnalytics(nameRoute, title = null) {
    let eventName = nameRoute;
    if (this.analyticsInstance) {
      this.setParams();
      this.analyticsInstance.set("location", nameRoute);

      const data = {
        page_path: eventName,
        page_title: title || nameRoute,
        page_location: window.location.href,
      };

      this.analyticsInstance.pageview(data);

      console.info("GA track page:", data);
    }
  }

  trackCustomEvents(
    nameRoute = null,
    eventAction = "",
    eventValue = "",
    featureName = null,
  ) {
    if (nameRoute) {
      let eventName = nameRoute;
      if (this.analyticsInstance) {
        this.setParams();

        if (featureName) {
          this.analyticsInstance.set("dimension4", featureName);
        }

        this.analyticsInstance.set("location", nameRoute);
        console.info("GA track event:", eventName, eventAction, eventValue);

        this.analyticsInstance.event(eventAction, {
          event_category: eventName,
          event_label: eventValue,
        });
      }
    }
  }

  setParams() {
    let generalConfig = this.app.generalConfig();
    if (this.analyticsInstance) {
      this.analyticsInstance.set("appName", generalConfig.analyticsAppName);
      this.analyticsInstance.set("dimension1", generalConfig.deviceType);
      this.analyticsInstance.set(
        "dimension2",
        LanguageBO.getInstance().currentLanguageCode(),
      );
      this.analyticsInstance.set(
        "dimension3",
        EnvironmentConfig.getValue("APP_API_USER_ID"),
      );
      this.analyticsInstance.set("dimension4", "generic");

      const origin = AppQueryContentManager.getInstance().origin;
      if (origin) {
        this.analyticsInstance.set("dimension4", origin);
      }

      this.analyticsInstance.set("hostname", document.location.origin);
      this.analyticsInstance.set("parameters", document.location.search);

      // Workaround
      this.analyticsInstance.query("consent", "default", {
        ad_storage: "denied",
      });
      //window["dataLayer"].push({
      //    'event': 'default_consent'
      //});
    }
  }
}

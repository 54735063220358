import OriginalFooter from "@mediktor-web/sdk/src/app/components/layout/footer/Footer";
import "./footer.scss";
import template from "./footer.html?raw";
import capitalize from "voca/capitalize";
import UIGridCell from "@mediktor-web/sdk/src/modules/core/components/interface/grid/GridCell";
import UIGridRow from "@mediktor-web/sdk/src/modules/core/components/interface/grid/GridRow";
import imgIconCe from "../../resources/img/mdk_logo_CE.png";
import imgIconGdpr from "../../resources/img/mdk_logo_GDPR.png";
import imgIconHippa from "../../resources/img/mdk_logo_HIPPA.png";
import imgValidationGdpr from "../../resources/img/mdk_logo_clinically_validated.png";
import iconInstagramImg from "../../resources/img/icon-social-instagram.png";
import { version as sdkVersion } from "@mediktor-web/sdk/package.json";
import LanguageBO from "@mediktor-web/sdk/src/modules/core/business/LanguageBO";
import RouteGenerator from "@mediktor-web/sdk/src/modules/core/lib/RouteGenerator";
import EnvironmentConfig from "@mediktor-web/sdk/src/modules/core/lib/EnvironmentConfig";
import packageJson from "../../../package.json";

export default {
  extends: OriginalFooter,
  template: OriginalFooter.template.replace(
    `<slot name="corp"></slot>`,
    template,
  ),
  //template: OriginalFooter.template.replace(`<div class="main-footer__inner" >`, `<div class="main-footer__inner" >${template}`), // TODO: add special tag to sdk!
  components: {
    "mdk-grid-cell": UIGridCell, //() => import("modules/core/components/interface/grid/GridCell"),
    "mdk-grid-row": UIGridRow, //() => import("modules/core/components/interface/grid/GridRow"),
  },
  computed: {
    versionDescription() {
      return `v.${packageJson.version} (SDK v.${sdkVersion})`;
    },
    classes() {
      return {
        "main-footer-corporate": true,
      };
    },

    linkList() {
      //if(this.isPhoneLayout) return [];

      let list = {};

      list[this.localeText("tmk2076")] = [
        {
          title: this.localeText("tmk2056"),
          route: {
            name: this.app().routeDefinitionName("corpBusinessSolutions"),
          },
          hasScroll: true,
        },
        {
          title: this.localeText("tmk1932"),
          route: {
            name: this.app().routeDefinitionName("certificate"),
          },
        },
      ];
      list[this.localeText("tmk2077")] = [
        {
          title: this.localeText("tmk2052"),
          route: `https://${
            EnvironmentConfig.getValue("APP_ENV") === "int"
              ? "www.int."
              : EnvironmentConfig.getValue("APP_ENV") !== "pro"
              ? "www.dev."
              : ""
          }mediktor.com?lang=${LanguageBO.getInstance().currentLanguageCode()}`,
          newTab: true,
        },
      ];
      list[this.localeText("tmk1175")] = [
        {
          title: this.localeText("tmk372"),
          route: {
            name: this.app().routeDefinitionName("glossary"),
          },
        },
      ];

      return list;
    },

    hasLocalesForTestimonials() {
      return (
        this.localeText("tmk1412") != null && this.localeText("tmk1413") != null
      );
    },
    socialList() {
      return [
        { src: iconInstagramImg, url: "https://www.instagram.com/mymediktor" },
      ];
    },
    imagesList() {
      return [imgIconCe, imgIconGdpr, imgIconHippa, imgValidationGdpr];
    },
    certsTitleText() {
      return this.localeText("tmk1176");
    },
    socialTitleText() {
      return this.localeText("tmk1652");
    },
    isMinimalLayout() {
      return true;
    },
    isDarkModeToggle() {
      return false;
    },
  },
  methods: {
    scrollToHowItWorks() {
      if (
        this.app().router.currentRoute.name ===
        RouteGenerator.getInstance().home().name
      ) {
        this.$root.$emit("scroll-to-how-it-works");
      } else {
        this.$router.push(RouteGenerator.getInstance().home()).catch(() => {});
        setTimeout(() => {
          this.$root.$emit("scroll-to-how-it-works");
        }, 500);
      }
    },
  },
  mounted() {},
};

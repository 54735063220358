import App from "./lib/App"; // Include the Application bootstrap class
import CheckerSkinEnum from "@mediktor-web/sdk/src/modules/core/enums/CheckerSkinEnum.js";
import EventBusSingleton from "@mediktor-web/sdk/dist/src/modules/core/lib/EventBusSingleton.js";
import EnvironmentConfig from "@mediktor-web/sdk/dist/src/modules/core/lib/EnvironmentConfig.js";

import AppPresets from "@mediktor-web/sdk/dist/src/modules/core/lib/AppPresets.js";
import config from "@mediktor-web/sdk/dist/src/modules/core/config/app.js";

import "@mediktor-web/sdk/dist/src/style.css";
import "./sass/main.scss"; // Include global custom styles

import mainLogo from "./resources/img/mmdkLogoBig.png";
import { mergeDeep } from "@mediktor-web/sdk/src/modules/core/lib/util/objectUtil.js";
import { GeneralConfig } from "@mediktor-web/sdk/src/modules/core/types/misc.js";

EventBusSingleton.onAll((eventName, ...eventData) => {
  if (eventName === "pageLoaded") {
  } else if (eventName === "trackCustomEvents") {
  } else {
    // Rest of events
  }
});

// ===========================================
// Override Mediktor's configuration
// Must be called BEFORE starting the application
// ===========================================

const environment = {
  APP_API_AUTH_USERNAME: import.meta.env.VITE_APP_API_AUTH_USERNAME,
  APP_API_AUTH_PASSWORD: import.meta.env.VITE_APP_API_AUTH_PASSWORD,
  APP_API_USER_ID: import.meta.env.VITE_APP_API_USER_ID,
  APP_API_AUTH_CODE: import.meta.env.VITE_APP_API_AUTH_CODE,
  APP_API_ENDPOINT: import.meta.env.VITE_APP_API_ENDPOINT,
  APP_WS_ENDPOINT: import.meta.env.VITE_APP_WS_ENDPOINT,
  APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  APP_BASE_PATH: import.meta.env.VITE_APP_BASE_PATH,
  REDSYS_LIVE: import.meta.env.VITE_REDSYS_LIVE === "true",
  REDSYS_KEY: import.meta.env.VITE_REDSYS_KEY,
  ENABLE_ERROR_REPORTING:
    import.meta.env.VITE_ENABLE_ERROR_REPORTING === "true",
  DEBUG_CAPABILITIES: import.meta.env.VITE_DEBUG_CAPABILITIES,
  FORCE_DOMAIN: import.meta.env.VITE_FORCE_DOMAIN,
  RUNTIME_MODE: import.meta.env.VITE_APP_RUNTIME_MODE === "true",
  GOOGLE_SIGNIN_KEY:
    "313684631062-1vgrfcrk6r4uhve24r56sg1egav2c8fk.apps.googleusercontent.com",
  SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN,
  GOOGLE_CAPTCHA_KEY: "6LcMJacUAAAAAKQFeSI3sZfz6GlZ0BeXzBYST2SC",
  APP_ID: "com.teckelmedical.mediktor",
  APP_ANALYTICS_ID: "G-7JEFPWW6FS",
  APP_GTM_CONTAINER_ID: "GTM-5RJDBGF",
  CONTACT_EMAIL_ADDRESS: import.meta.env.VITE_APP_CONTACT_EMAIL_ADDRESS,
  HHRR_CONTACT_EMAIL_ADDRESS: import.meta.env
    .VITE_APP_HHRR_CONTACT_EMAIL_ADDRESS,
  REDSYS_MERCHANT_CODE: "327676540",
  STRIPE_KEY: "pk_test_rTNDwRUeMhKNCGk4cVGfVWO7",
};

// let config = App.getInstance().generalConfig();
config.mainLogo = mainLogo;
// Header logo (to see, change mainVisualNavigationEnabled)
config.scheduleEnabled = false;
config.prescriptionsEnabled = false;
config.valuationEnabled = false;
config.dashboardEnabled = true;
config.dashboardRedirectEnabled = true;
config.chatDirectAccessEnabled = false;
config.impersonatedSessionEnabled = false;
config.paymentGatewayType = "redsys";
config.isProfileEnrichment = true;
config.chatbotId = "mediktorChatbotId-v3";
config.enableSocket = true;
config.checkerSkin = CheckerSkinEnum.AVATAR;
config.enableSounds = true;
config.enableProductUpgradeModal = true;
config.enableToggleDarkMode = true;
config.enableAppleSignIn = true;
config.firebaseConfig = {
  apiKey: "AIzaSyAHdEvppFxJUB8kNrQkxRVGGxwuJCKTLjw",
  authDomain: "api-project-313684631062.firebaseapp.com",
  databaseURL: "https://api-project-313684631062.firebaseio.com",
  projectId: "api-project-313684631062",
  storageBucket: "api-project-313684631062.appspot.com",
  messagingSenderId: "313684631062",
  appId: "1:313684631062:web:38ef2738c4c626de515f37",
  measurementId: "G-LB14LCQL7P",
};
config.showCorporateContent = true;
config.enableGenerateDynamicMetas = true;
config.googleSignInId = environment.GOOGLE_SIGNIN_KEY;
config.showDevelopmentTip = false;
config.enableUserDebugger = false;
config.checker.enableDefaultCancelRedirection = true;
config.checker.enableDefaultCloseRedirection = true;
config.routerMode = "history";
config.legalTerms.dataProtectionModule = true;
config.pullFullLocalizationList = true;
config.legalTermsTextLocaleId = "tmk1090";
config.privacyPolicyTextLocaleId = "tmk1610";
config.cookiesTextLocaleId = "tmk1609";
config.legalTerms.showContentInModal = false;
config.enableSymptomsInGlossary = true;
config.enableToggleDarkMode = false;
config.checker.enableGiveFeedbackButton = true;
config.showCertificate = false;
config.enableMiniNav = false;

// Load app presets
const preset = AppPresets.getInstance().getActivePreset();

const configTmp: any = {};
if (preset) {
  Object.entries(preset.config as object).forEach((value) => {
    configTmp[value[0]] =
      typeof value[1] === "string" && value[1].includes("{")
        ? JSON.parse(value[1])
        : value[1];
  });
}

preset && mergeDeep<Partial<GeneralConfig>>(config, configTmp);

// Start the application
App.setEnvironmentConfig(environment);

App.getInstance()
  .start()
  .then(() => {
    // The instance is loaded
  });
